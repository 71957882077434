<template>
    <div>
        <div class="form-group">
            <label for="configuracoes-client_id" class="form-label">Client ID</label>
            <input type="text" class="form-control" id="configuracoes-client_id" v-model="client_id" />
        </div>
        <div class="form-group">
            <label for="configuracoes-client_secret" class="form-label">Client Secret</label>
            <input type="text" class="form-control" id="configuracoes-client_secret" v-model="client_secret" />
        </div>

        <div class="row">

            <div class="col-12 mb-3">
                <h5 class="title">
                    Equivalência de Tipos
                </h5>
                <p>Selecione o tipo do Lead Lar esquivalente a cada tipo do Orulo.</p>
            </div>
            <div class="col-12">
                <div class="row mb-3">
                    <div class="col-6">Orulo</div>
                    <div class="col-6">Lead Lar</div>
                </div>
                <div class="row mb-3" v-for="config in configuracao_tipos">
                    <div class="col-6">
                        <input type="text" class="form-control" disabled readonly
                            :value="config.tipo_orulo">
                    </div>
                    <div class="col-6">
                        <select class="form-control" v-model="config.tipo_leadlar_id">
                            <option :value="0" :is_selected="0 === Number(config.tipo_leadlar_id)">Inativo
                            </option>
                            <option :value="tipo.id" v-for="tipo in tipos">{{ tipo.titulo }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" >
            <div class="col-12 mb-3">
                <h5 class="title">
                    Equivalência de Características
                </h5>
                <p>Selecione o tipo do Lead Lar esquivalente a cada característica do Orulo.</p>
            </div>
            <div class="col-12">
                <div class="row mb-3">
                    <div class="col-6">Orulo</div>
                    <div class="col-6">Lead Lar</div>
                </div>
                <div class="row mb-3" v-for="caracteristica_config in configuracao_caracteristicas">
                    <div class="col-6">
                        <input type="text" class="form-control" disabled readonly
                            :value="caracteristica_config.caracteristica_orulo">
                    </div>
                    <div class="col-6">
                        <select class="form-control" v-model="caracteristica_config.caracteristica_leadlar_id">
                            <option :value="0" :is_selected="0 === Number(caracteristica_config.caracteristica_leadlar_id)">
                                Inativo</option>
                            <option :value="caracteristica.id" v-for="caracteristica in caracteristicas">{{
                                caracteristica.titulo }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script lang="ts" setup>

const tipos_orulo: string[] = [
    "Apartamento",
    "Casa",
    "Casa em Condomínio",
    "Cobertura Duplex",
    "Cobertura Horizontal",
    "Duplex",
    "Edifício Comercial",
    "Fazenda/Sítio",
    "Galpão/Pavilhão",
    "Garden",
    "Loft",
    "Loja",
    "Sala",
    "Sala com Área Ext.",
    "Studio",
    "Terreno/Lote Comercial",
    "Terreno/Lote Residencial",
    "Triplex"
]

const caracteristicas_orulo: string[] = [
    "Acessibilidade universal",
    "Acesso PCD",
    "Ar condicionado central",
    "Auditório",
    "Bar",
    "Bicicletário",
    "Brinquedoteca",
    "Business place",
    "Centro de convenções",
    "Chimarródromo",
    "Churrasqueira condominial",
    "Cinema",
    "Circuito de TV",
    "Condomínio fechado",
    "Copa",
    "Deck molhado",
    "Depósito",
    "Elevador codificado",
    "Elevador de serviço",
    "Elevador social",
    "Energia solar",
    "Entrada de serviço",
    "Entrada para caminhões",
    "Escada rolante",
    "Espaço café",
    "Espaço gourmet",
    "Estacionamento para visitantes",
    "Estacionamento rotativo",
    "Fire place",
    "Fitness",
    "Fitness ao ar livre",
    "Forro rebaixado",
    "Gerador",
    "Gás central condominial",
    "Hall com pé direito triplo",
    "Hall de entrada com pé direito duplo",
    "Heliponto",
    "Horta coletiva",
    "Infraestrutura para automação",
    "Jardim",
    "Lan house",
    "Lava-rápido",
    "Lavanderia",
    "Lounge",
    "Pet place",
    "Piscina adulto",
    "Piscina aquecida",
    "Piscina com raia",
    "Piscina infantil",
    "Piscina térmica",
    "Piso elevado",
    "Pista caminhada",
    "Playground",
    "Portaria",
    "Portaria 24 horas",
    "Porte cochère",
    "Porteiro eletrônico",
    "Praça",
    "Pub",
    "Quadra futebol sete",
    "Quadra paddle",
    "Quadra poliesportiva",
    "Quadra tênis",
    "Quadra volei",
    "Refeitório",
    "Restaurante",
    "Sala de jogos",
    "Sala de reuniões",
    "Sala de segurança",
    "Salão de festas",
    "Sauna",
    "Segurança",
    "Shaft de telecomunicações",
    "Solarium",
    "Spa",
    "Terraço",
    "Terraço coletivo",
    "Tomada carro elétrico",
    "Trilhas e bosque",
    "Vestiários",
    "Zelador"
]

import { computed, ref, watch } from 'vue';
import Slugify from 'slugify';
import { Tipo, tipo_repository } from '@/store/tipos';
import { Caracteristica, caracteristica_repository } from '@/store/caracteristicas';
import { v7 } from 'uuid'

const slugify = (str: string): string => {
    return Slugify(str, {
        locale: 'pt',
        lower: true
    })
}

type ConfiguracaoTipo = {
    id: string
    tipo_leadlar_id: number
    tipo_orulo: string
}

type ConfiguracaoCaracteristica = {
    id: string
    caracteristica_leadlar_id: number
    caracteristica_orulo: string
}

interface Props {
    modelValue: Record<string, any>
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: Record<string, any>): void
}>()

let default_tipos: ConfiguracaoTipo[] = tipos_orulo.map(tipo_orulo => ({
    id: v7(),
    tipo_orulo,
    tipo_leadlar_id: 0
}))

let default_caracteristicas: ConfiguracaoCaracteristica[] = caracteristicas_orulo.map(caracteristica_orulo => ({
    id: v7(),
    caracteristica_orulo,
    caracteristica_leadlar_id: 0
}))

if (props.modelValue.tipos) {
    for (let config of props.modelValue.tipos) {
        let row = default_tipos.find(({ tipo_orulo }) => tipo_orulo === config.tipo_orulo)
        if (!row) {
            continue
        }
        row.tipo_leadlar_id = config.tipo_leadlar_id
    }
}
if (props.modelValue.caracteristicas) {
    for (let config of props.modelValue.caracteristicas) {
        let row = default_caracteristicas.find(({ caracteristica_orulo }) => caracteristica_orulo === config.caracteristica_orulo)
        if (!row) {
            continue
        }
        row.caracteristica_leadlar_id = config.caracteristica_leadlar_id
    }
}

const tipos = ref<Tipo[]>([])
const caracteristicas = ref<Caracteristica[]>([])
const configuracao_tipos = ref<ConfiguracaoTipo[]>(default_tipos)
const configuracao_caracteristicas = ref<ConfiguracaoCaracteristica[]>(default_caracteristicas)
const client_id = ref<string>(props.modelValue.client_id||'')
const client_secret = ref<string>(props.modelValue.client_secret||'')



const loadTipos = async () => { 
    try {
        tipos.value = await tipo_repository.getAll()
        for (let tipo of tipos.value) {
            let tipo_orulo = tipos_orulo.find(tipo_orulo => slugify(tipo_orulo) === slugify(tipo.titulo))
            let config = configuracao_tipos.value.find(row => row.tipo_orulo === tipo_orulo)
            if (!config) {
                continue
            }
            config.tipo_leadlar_id = tipo.id
        }
    } catch (error) {
        console.error(error)
    }
}
const loadCaracteristicas = async () => {
    try {
        caracteristicas.value = await caracteristica_repository.search()
        for (let caracteristica of caracteristicas.value) {
            let caracteristica_orulo = caracteristicas_orulo.find(caracteristica_orulo => slugify(caracteristica_orulo) === slugify(caracteristica.titulo))
            const config = configuracao_caracteristicas.value.find(row => row.caracteristica_orulo === caracteristica_orulo)
            if (!config) {
                continue
            }
            config.caracteristica_leadlar_id = caracteristica.id            
        }
    } catch (error) {
        console.log(error)
    }
}

const update = () => {
    emit('update:modelValue', {
        ...props.modelValue,
        client_id: client_id.value,
        client_secret: client_secret.value,
        tipos: configuracao_tipos.value,
        caracteristicas: configuracao_caracteristicas.value
    })
}

const load = async () => {
    await Promise.all([
        loadTipos(),
        loadCaracteristicas()
    ])
    update()
}




watch(client_id, () => {
    update()
})

watch(client_secret, () => {
    update()
})

watch(configuracao_tipos, () => {
    update()
})

watch(configuracao_caracteristicas, () => {
    update()
})

load()

</script>
<style>
.modal-content {
    width: 80vw;
}

input:invalid {
    color: red;
}
</style>