import { ref } from "vue"

export type Dominio = {
    id: number,
    account_id: number
    titulo: string
    default: boolean
    ativo: boolean
}

export const dominios = ref<Dominio[]>([])
export const loadDominios = async () => {
    try {
        const records = await fetch('dominios/get').then(res => res.json())
        dominios.value = records
    } catch (error) {
        
    }
}

loadDominios()