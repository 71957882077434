<template>
    <div>
        <div class="form-group">
            <label for="formato-links-topo">Formato dos Links</label>
            <select class="form-control" id="formato-links-topo" v-model="formato">
                <option value="Horizontal">Horizontal</option>
                <option value="Droplist">Lista</option>
            </select>
        </div>

        <div class="form-group" v-if="formato === 'Droplist'">
            <Label for="titulo">Título</Label>
            <input type="text" name="titulo" id="titulo" v-model="titulo" class="form-control" />
        </div>

        <hr class="my-4">

        <div class="card" v-for="link in links">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <a :href="link.link" target="_blank" class="btn"
                        :style="`background-color: ${link.background_color};color: ${link.color};`">{{ link.titulo ||
                        'Título do link'
                        }}</a>
                    <div>
                        <button class="btn btn-danger btn-sm" type="button" @click="() => removerLink(link.id)"><i
                                class="fa fa-trash"></i></button>
                    </div>
                </div>
            </div>
            <div class="card-body p-3">

                <div class="form-group">
                    <input type="text" class="form-control mb-3" placeholder="Título" v-model="link.titulo" />
                </div>
                <div class="form-group">
                    <input type="text" class="form-control mb-3" placeholder="https://www.google.com.br"
                        v-model="link.link" />
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Cor de fundo </label>
                            <input type="color" name="background_color" class="form-control"
                                v-model="link.background_color" :disabled="link.fundo_transparente">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Cor da fonte </label>
                            <input type="color" name="color" class="form-control" v-model="link.color">
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="form-check d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" :value="true"
                                v-model="link.fundo_transparente" :id="`checkbox-fundo-transparente-{{ link.id }}`">
                            <label class="form-check-label" :for="`checkbox-fundo-transparente-{{ link.id }}`">
                                Fundo Transparente
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-check d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" :value="true" v-model="link.nova_guia"
                                :id="`checkbox-nova-guia-{{ link.id }}`">
                            <label class="form-check-label" :for="`checkbox-nova-guia-{{ link.id }}`">
                                Abrir em nova guia
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center">

            <button class="btn btn-secondary" type="button" @click="novoLink">
                <i class="fa fa-plus"></i>
                Adicionar
            </button>
        </div>

    </div>

</template>
<script lang="ts" setup>

import { ref, watch } from 'vue';
import { v7 as uuidv7 } from 'uuid'
import Swal from 'sweetalert2';


type LinkTopo = {
    id: string
    titulo: string
    link: string
    background_color: string
    color: string
    nova_guia: boolean
    fundo_transparente: boolean
}


interface Props {
    modelValue: Record<string, any>
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: Record<string, any>): void
}>()

const links = ref<LinkTopo[]>(JSON.parse(JSON.stringify(props.modelValue.links)).map((link: LinkTopo) => {
    return {
        ...link,
        nova_guia: link.nova_guia === true || 'true' === `${link.nova_guia}`,
        fundo_transparente: link.fundo_transparente === true || 'true' === `${link.fundo_transparente}`,
    }
})||[])
const formato = ref<'Horizontal'|'Droplist'>(props.modelValue.formato || 'Horizontal')
const titulo = ref<string>(props.modelValue.titulo || 'Atalhos')

const novoLink = () => {
    links.value.push({
        id: uuidv7(),
        titulo: '',
        link: '',
        background_color: '#000000',
        color: '#ffffff',
        nova_guia: true,
        fundo_transparente: false
    })
}
const removerLink = async (id: string) => {
    const { isConfirmed } = await Swal.fire({
        icon: "warning",
        title: "Deseja remover o link?",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: 'Sim'
    })
    if (!isConfirmed) {
        return
    }
    links.value = links.value.filter((link) => link.id !== id)
}

watch(formato, () => {
    emit('update:modelValue', {
        ...props.modelValue,
        formato: formato.value
    })
})

watch(titulo, () => {
    emit('update:modelValue', {
        ...props.modelValue,
        titulo: titulo.value
    })
})


watch(links, () => {
  
    
    emit('update:modelValue', {
        ...props.modelValue,
        links: links.value.map(link => {
            return {
                ...link,
                nova_guia: link.nova_guia===true||'true' === `${link.nova_guia}`,
                fundo_transparente: link.fundo_transparente===true||'true' === `${link.fundo_transparente}`,
            }
        })
    })
}, {
    deep: true
})

watch(() => props.modelValue, () => {
    
    formato.value = props.modelValue.formato || 'Horizontal'
    titulo.value = props.modelValue.titulo || 'Atalhos'    
    return
    links.value = JSON.parse(JSON.stringify(props.modelValue.links)).map((link: LinkTopo) => {
        return {
            ...link,
            nova_guia: link.nova_guia === true || 'true' === `${link.nova_guia}`,
            fundo_transparente: link.fundo_transparente === true || 'true' === `${link.fundo_transparente}`,
        }
    }) || []
    
})



</script>