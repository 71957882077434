<template>
    <div class="row">
        <div class="col-12 mb-3">
            <h5 class="title">Equivalência de Campos</h5>
        </div>
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-6">Lead Lar</div>
                <div class="col-6">Vista Soft</div>
            </div>
            <div class="row mb-3" v-for="campo in campos_leadlar">
                <div class="col-6">
                    <input type="text" class="form-control" disabled readonly
                        :value="campo.label">
                </div>
                <div class="col-6">
                    <select class="form-control" v-model="campos[campo.leadlar]">
                        <option value="" :is_selected="campos[campo.leadlar] === ''">Inativo</option>
                        <option :value="campo_vista" v-for="campo_vista in campos_vista">{{ campo_vista }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';


interface Props {
    campos_vista: string[]
    modelValue: Record<string, string>
}


const props = defineProps<Props>()
const emits = defineEmits<{
    (e: 'update:modelValue', v: Record<string, string>): void
}>()

const campos_leadlar = [    
    {
        leadlar: 'Codigo',
        label: 'Código',
        vista: ['Codigo']
    },
    {
        leadlar: 'UF',
        label: 'UF',
        vista: ['UF']
    },
    {
        leadlar: 'Cidade',
        label: 'Cidade',
        vista: ['Cidade']
    },
    {
        leadlar: 'Bairro',
        label: 'Bairro',
        vista: ['Bairro']
    },    
    {
        leadlar: 'BairroComercial',
        label: 'Bairro Comercial',
        vista: ['BairroComercial']
    },
    {
        leadlar: 'Endereco',
        label: 'Logradouro',
        vista: ['Endereco']
    },
    {
        leadlar: 'Complemento',
        label: 'Complemento',
        vista: ['Complemento']
    },
    {
        leadlar: 'CEP',
        label: 'CEP',
        vista: ['CEP']
    },
    {
        leadlar: 'Categoria',
        label: 'Categoria',
        vista: ['Categoria']
    },
    
    {
        leadlar: 'Categoria',
        label: 'Categoria',
        vista: ['Categoria']
    },
    {
        leadlar: 'TituloSite',
        label: 'Título',
        vista: ['TituloSite', 'TituloWeb']
    },
    {
        leadlar: 'DescricaoWeb',
        label: 'Descrição',
        vista: ['DescricaoWeb', 'Descricao']
    },
    {
        leadlar: 'DestaqueWeb',
        label: 'Destaque',
        vista: ['DestaqueWeb', 'SuperDestaqueWeb', 'Destaque']
    },
    {
        leadlar: 'Lancamento',
        label: 'Lançamento',
        vista: ['Lancamento']
    },
    {
        leadlar: 'Empreendimento',
        label: 'Empreendimento',
        vista: ['Empreendimento']
    },
    {
        leadlar: 'BanheiroSocialQtd',
        label: 'Banheiros',
        vista: ['BanheiroSocialQtd', 'Banheiros']
    },
    {
        leadlar: 'CodigoEmpreendimento',
        label: 'Codigo Empreendimento',
        vista: ['CodigoEmpreendimento']
    },
    {
        leadlar: 'Status',
        label: 'Status',
        vista: ['Status']
    },
    {
        leadlar: 'ValorVenda',
        label: 'Valor Venda',
        vista: ['ValorVenda']
    },
    {
        leadlar: 'ValorLocacao',
        label: 'Valor Locação',
        vista: ['ValorLocacao']
    },
    {
        leadlar: 'ValorCondominio',
        label: 'Valor Condomínio',
        vista: ['ValorCondominio']
    },
    {
        leadlar: 'ValorIptu',
        label: 'Valor IPTU',
        vista: ['ValorIptu']
    },
    {
        leadlar: 'Dormitorios',
        label: 'Dormitórios',
        vista: ['Dormitorios']
    },
    {
        leadlar: 'Suites',
        label: 'Suítes',
        vista: ['Suites', 'Suite']
    },
    {
        leadlar: 'Vagas',
        label: 'Vagas',
        vista: ['Vagas']
    },
    {
        leadlar: 'Latitude',
        label: 'Latitude',
        vista: ['Latitude']
    },
    {
        leadlar: 'Longitude',
        label: 'Longitude',
        vista: ['Longitude']
    },
    {
        leadlar: 'AreaPrivativa',
        label: 'Área Privativa',
        vista: ['AreaPrivativa']
    },
    {
        leadlar: 'AreaTotal',
        label: 'Área Total',
        vista: ['AreaTotal']
    },


    
    
    
    
   
    
    
    
    
    
   
    
]

const campos = ref<Record<string, string>>({ ...(props.modelValue || {}) })

const compare = () => {
    for (let { leadlar, vista } of campos_leadlar) {
        if (leadlar in campos.value && '' !== campos.value[leadlar]) {
            continue
        }

        campos.value[leadlar] = ''

        for (let campo_vista of vista) {
            if (props.campos_vista.includes(campo_vista)) {
                campos.value[leadlar] = campo_vista
                break
            }
        }

    }
    
}

watch(() => props.campos_vista, () => {
    compare()
})





watch(campos, () => {
    emits('update:modelValue', { ...campos.value })
    
}, {
    deep: true
})

compare()

</script>