<template>
    <div>

        <div class="row">

            <div class="col-12 mb-3">
                <h5 class="title">URL</h5>
                <p>
                    <a :href="url" target="_blank">
                        {{ url }}
                    </a>
                </p>
                <h5 class="title">
                    Equivalência de Tipos
                </h5>
                <p>Selecione o tipo do Chaves na mão esquivalente a cada tipo do Lead Lar.</p>

            </div>
            <div class="col-12" :key="JSON.stringify(tipos)">
                <div class="row mb-3">
                    <div class="col-6">Chaves na mão</div>
                    <div class="col-6">Lead Lar</div>
                </div>
                <div class="row mb-3" v-for="config in configuracao_tipos">
                    <div class="col-6">
                        <input type="text" class="form-control" disabled readonly
                            :value="tipos_map.get(config.tipo_leadlar_id)">
                    </div>
                    <div class="col-6">
                        <select class="form-control" v-model="config.tipo_chaves_na_mao">
                            <option :value="''" :is_selected="'' === config.tipo_chaves_na_mao">Inativo
                            </option>
                            <option :value="tipo" v-for="tipo in tipos_chaves_na_mao">{{ tipo }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-3">
                <h5 class="title">Equivalência de características</h5>
                <p>Selecione a característica do Chaves na mão equivalente a cada característica do Lead Lar.</p>
            </div>
            <div class="col-12" :key="JSON.stringify(caracteristicas)">
                <div class="row mb-3">
                    <div class="col-6">Chaves na mão</div>
                    <div class="col-6">Lead Lar</div>
                </div>
                <div class="row mb-3" v-for="config in configuracao_caracteristicas">
                    <div class="col-6">
                        <input type="text" disabled readonly class="form-control"
                            :value="caracteristica_map.get(config.caracteristica_leadlar_id)">
                    </div>
                    <div class="col-6">
                        <select class="form-control" v-model="config.caracteristica_chaves_na_mao">
                            <option :value="''" :is_selected="'' === config.caracteristica_chaves_na_mao">Inativo
                            </option>
                            <option :value="caracteristica" v-for="caracteristica in caracteristicas_chaves_na_mao">{{ caracteristica }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>
<script lang="ts" setup>

const tipos_chaves_na_mao: string[] = [
    "Apartamento",
    "Casa / Sobrado",
    "Casa / Sobrado Comercial",
    "Casa / Sobrado em Condomínio",
    "Cobertura",
    "Conj. Comercial / Sala",
    "Fazenda",
    "Flat",
    "Galpão / Depósito",
    "Garagem",
    "Kitnet / Stúdio",
    "Loft",
    "Ponto Comercial",
    "Prédio",
    "Sítio / Chácara",
    "Terreno comercial",
    "Terreno / Lote",
    "Terreno em Condomínio"
]

const infra_chaves_na_mao: string[] = [
    "Academia",
    "Aceita animais",
    "Acessibilidade",
    "Acesso a praia",
    "Acesso com asfalto",
    "Acesso com senha",
    "Acesso com tag",
    "Acesso de pedestres",
    "Acesso de serviço",
    "Acesso exclusivo para pet",
    "Acesso exclusivo para praia",
    "Acesso independente",
    "Acesso para PNE",
    "Administração airbnb",
    "Água",
    "Água coletiva",
    "Alarme",
    "Ambiente climatizado",
    "Ambientes equipados e decorados",
    "Amortecedores de impacto nas garagens",
    "Ante sala",
    "Antena coletiva",
    "Antipó",
    "Aquecedor central de passagem",
    "Aquecimento",
    "Aquecimento à gás",
    "Aquecimento central",
    "Aquecimento elétrico e a gás",
    "Ar condicionado",
    "Área comum equipada e decorada",
    "Área de descanso",
    "Área de jogos externo",
    "Área de lazer",
    "Área de manobra",
    "Área de recreação",
    "Área de serviço",
    "Área para fumantes",
    "Área para pets",
    "Área preservada",
    "Área verde",
    "Área verde de preservação",
    "Armário(s) planejados",
    "Ateliê",
    "Auditório",
    "Banheiro(s)",
    "Banheiro(s) PNE",
    "Bar",
    "Beautycare",
    "Bebedouro",
    "Berçário",
    "Bicicletário",
    "Bicicletas compartilhadas",
    "Bike share",
    "Biometria",
    "Boliche",
    "Bosque",
    "Box de garagem",
    "Brinquedoteca",
    "Bwc coletivo",
    "BWC empregada",
    "Cachoeira(s)",
    "Calçada",
    "Calçada em paver",
    "Câmeras",
    "Campo c/ grama sintética",
    "Campo de futebol",
    "Campo de futebol suíço",
    "Campo de golfe",
    "Campo futebol areia",
    "Cancha de bocha",
    "Captação de água da chuva",
    "Captação de energia solar",
    "Car Space",
    "Carteado",
    "Casa da zeladoria",
    "Casa de boneca",
    "Casa de caseiro",
    "Casa do tarzan",
    "Catraca de identificação",
    "Cerca de vidro",
    "Cerca elétrica",
    "Cercado",
    "Chafariz",
    "Children care",
    "Churrasqueira(s) coletiva(s)",
    "Chuveiro (saída de praia)",
    "Ciclovia no condomínio",
    "Cinema",
    "Circuito de caminhada",
    "Circuito funcional",
    "Circuito Interno de TV",
    "Cisterna",
    "Coleta seletiva de lixo",
    "Concierge 24 horas",
    "Condomínio fechado",
    "Coworking",
    "Cozinha ampla",
    "Cozinha com armário(s)",
    "Cozinha compartilhada",
    "Deck",
    "Dependência de empregados",
    "Depósito",
    "Despensa",
    "Dry wash car",
    "Ducha",
    "Eclusa de veículos",
    "Eclusa social",
    "Edícula com churrasqueira",
    "Elevador panorâmico",
    "Elevador para automóvel",
    "Elevador para Carro",
    "Elevador(es)",
    "Elevador(es) com biometria",
    "Elevador(es) com sistema regenerativo de energia",
    "Elevador(es) de acesso PNE",
    "Elevador(es) de serviço",
    "Entrada em nível",
    "Entrada lateral",
    "Entrada p/ visitantes",
    "Entrada para banhistas",
    "Entrada social e de serviço",
    "Espaço ao ar livre",
    "Espaço Business",
    "Espaço café",
    "Espaço chimarrão",
    "Espaço cultural",
    "Espaço de convivência",
    "Espaço de descanso",
    "Espaço de leitura",
    "Espaço delivery",
    "Espaço família",
    "Espaço fitness",
    "Espaço Garden",
    "Espaço gourmet",
    "Espaço infantil",
    "Espaço jovem",
    "Espaço kids",
    "Espaço mulher",
    "Espaço Náutico",
    "Espaço para adolescentes",
    "Espaço para artes",
    "Espaço play",
    "Espaço pub",
    "Espaço redário",
    "Espaço terceira idade",
    "Espaço verde",
    "Espaço web",
    "Espaço zen",
    "Espelho d'água",
    "Estacionamento com sistema valet",
    "Estacionamento para veículos",
    "Estacionamento rotativo",
    "Estar com pergolado",
    "Estrutura completa de clube",
    "Estúdio de música",
    "Estúdio de pilates",
    "Estufa",
    "Face norte",
    "Fechadura digital",
    "Fireplace",
    "Fogão",
    "Fogão a lenha",
    "Fonte de água",
    "Forno a lenha",
    "Forno elétrico",
    "Forro rebaixado",
    "Fraldário",
    "Galeria águas pluviais",
    "Galeria de Arte",
    "Game center",
    "Garagem automatizada",
    "Garagem coberta",
    "Garagem com pintura epóxi",
    "Garagem de barco",
    "Gás canalizado",
    "Gás central",
    "Gazebo",
    "Gerador de energia",
    "Gibiteca",
    "Gradeado",
    "Guarita",
    "Guarita blindada",
    "Hall com pé direito duplo",
    "Hall com pé direito triplo",
    "Hall de entrada decorado",
    "Heliponto",
    "Hidromassagem",
    "Horta",
    "Iluminação inteligente",
    "Infraestrutura ar condicionado",
    "Interfone",
    "Internet predial",
    "Isolamento acústico",
    "Isolamento termoacústico",
    "Jardim",
    "Lago",
    "Lan house",
    "Lareira",
    "Lareira externa",
    "Lava car",
    "Lava Pés",
    "Lavanderia compartilhada",
    "Living",
    "Lounge",
    "Luzes de emergência",
    "Manobrista",
    "Manta asfáltica na laje",
    "Máquinas de venda automática",
    "Marina para barcos",
    "Market four you",
    "Mata nativa",
    "Medidor individual",
    "Medidores individuais de gás e água",
    "Mercado de conveniências",
    "Mina d'água",
    "Mini lago",
    "Mirante",
    "Multiplay",
    "Murado",
    "Muros em vidro",
    "Observatório",
    "Office center",
    "Oficina",
    "Ofurô",
    "Painel fotovoltaico",
    "Paisagismo",
    "Parque gramado",
    "Passa-volume para delivery",
    "Patinetes elétricos compartilhados",
    "Pátio para manobra",
    "Pé direito duplo",
    "Pé direito elevado",
    "Permitido pets",
    "Personal trainer do condomínio",
    "Pier",
    "Pilotis",
    "Piscina",
    "Piscina aquecida",
    "Piscina borda infinita",
    "Piscina coberta",
    "Piscina com bar",
    "Piscina com hidromassagem",
    "Piscina infantil",
    "Piso anti ruído",
    "Piso de ardósia",
    "Piso de granito",
    "Piso de madeira",
    "Piso de mármore",
    "Piso elevado",
    "Piso laminado",
    "Piso porcelanato",
    "Pista de corrida/caminhada",
    "Pista de patinação",
    "Pista de skate",
    "Playground",
    "Pocket park",
    "Poço",
    "Pomar",
    "Pool lounge",
    "Port cocherè",
    "Porta blindada",
    "Portão",
    "Portão basculante",
    "Portão com eclusa",
    "Portão eletrônico",
    "Portaria",
    "Portaria 24h",
    "Portaria com biometria",
    "Portaria das 7 as 23hs",
    "Portaria remota",
    "Portaria virtual",
    "Portas anti impacto",
    "Portas automatizadas",
    "Porteiro eletrônico",
    "Praça boulevard",
    "Praça de convívio",
    "Prédio pastilhado",
    "Prédio sem elevador",
    "Projeto de automação",
    "Pulmão de segurança",
    "Quadra com grama",
    "Quadra com grama sintética",
    "Quadra de areia",
    "Quadra de beach tênis",
    "Quadra de esporte",
    "Quadra de futebol",
    "Quadra de futebol de salão",
    "Quadra de padel",
    "Quadra de peteca",
    "Quadra de tênis",
    "Quadra poliesportiva",
    "Quadra recreativa",
    "Quadra squash",
    "Quiosque com churrasqueira",
    "Quiosque gourmet",
    "Quiosque(s)",
    "Rede de energia subterrânea",
    "Rede de tratamento de esgoto",
    "Refeitório",
    "Reserva",
    "Restaurante privativo",
    "Reuso de águas pluviais",
    "Riacho",
    "Ronda/Vigilância",
    "Sala de atendimento",
    "Sala de beleza",
    "Sala de entregas",
    "Sala de estética",
    "Sala de estudos",
    "Sala de Eventos",
    "Sala de ferramentas",
    "Sala de filmes",
    "Sala de ginástica",
    "Sala de massagem",
    "Sala de pilates",
    "Sala de poker",
    "Sala de recepção",
    "Sala de tênis de mesa",
    "Sala de TV",
    "Sala de vídeo",
    "Sala multimídia",
    "Sala para funcionários",
    "Sala reuniões",
    "Sala yoga",
    "Sala(s) de convenções",
    "Salão com churrasqueira",
    "Salão de festas",
    "Salão de festas com churrasqueira",
    "Salão de jogos",
    "Salão de jogos com churrasqueira",
    "Salão multiuso",
    "Sauna",
    "Segurança 24h",
    "Segurança monitorada",
    "Segurança veicular",
    "Sensor de perímetro",
    "Sensor de presença na área comum",
    "Sensor de presença na garagem",
    "Serviços pay per use",
    "Sistema de câmeras",
    "Sistema de captação de água",
    "Sistema de clausura",
    "Sistema de irrigação no jardim",
    "Sistema de luz solar",
    "Sistema de monitoramento",
    "Sistema de prevenção de incêndio",
    "Solárium",
    "Som ambiente nas áreas comuns",
    "Spa",
    "Sport lounge",
    "Taberna",
    "Tanque para peixe",
    "Telhado verde",
    "Terraço",
    "Terraço com churrasqueira",
    "Terraço com jacuzzi",
    "Terraço garden",
    "Teto em gesso",
    "Teto rebaixado",
    "Tomada para bicicleta elétrica",
    "Tomadas para carros elétricos",
    "Trapiche",
    "Trilha para caminhar",
    "Vaga carga e descarga",
    "Vaga de embarque",
    "Vaga(s) de garagem",
    "Vaga(s) para motos",
    "Vaga(s) para visitantes",
    "Varal Coletivo",
    "Vestiário",
    "Via de desaceleração",
    "Vista para o mar",
    "Wi-fi",
    "Workspace",
    "Xadrez gigante",
    "Zelador"
]

const caracteristicas_chaves_na_mao: string[] = [
    "2° piso",
    "Acabamento em alto padrão",
    "Acabamento em mármore",
    "Academia",
    "Acesso de serviço",
    "Acesso PNE",
    "Açude",
    "Adega",
    "Alarme",
    "Ambiente climatizado",
    "Ambiente(s) amplo(s)",
    "Ambientes integrados",
    "Ante sala",
    "Aquecedor",
    "Aquecedor à gás",
    "Aquecedor de passagem",
    "Aquecedor elétrico",
    "Aquecimento a gás",
    "Ar Condicionado",
    "Área de carga e descarga",
    "Área de circulação",
    "Área de descanso",
    "Área de estar coberta",
    "Área de fogueira",
    "Área de lazer",
    "Área de serviço",
    "Área de serviço ampla",
    "Área de serviço com armários",
    "Área de serviço com banheiro",
    "Área de serviço com depósito",
    "Área externa",
    "Área externa coberta",
    "Área técnica",
    "Armário(s) embutido(s)",
    "Armário(s) planejado(s)",
    "Ático",
    "Ático com banheiro",
    "Ático com lareira",
    "Auditório",
    "Automatizada",
    "Baias de cavalo",
    "Bancada",
    "Banheira",
    "Banheiro amplo",
    "Banheiro auxiliar",
    "Banheiro boudoir",
    "Banheiro com box blindex",
    "Banheiro com chuveiro duplo",
    "Banheiro com ducha dupla",
    "Banheiro com hidromassagem",
    "Banheiro com sauna",
    "Banheiro funcionária(o)",
    "Banheiro PNE",
    "Banheiro Senhor e Senhora",
    "Banheiro social",
    "Banheiro(s) com armário(s)",
    "Bar molhado",
    "Biblioteca",
    "Bicicletário",
    "Biometria",
    "Blackout nas janelas",
    "Box de praia",
    "Box no banheiro",
    "Brinquedoteca",
    "Bwc Sr.",
    "Bwc Sr. e Sra.",
    "Bwc Sra.",
    "Cachoeira",
    "Câmara fria",
    "Câmeras de monitoramento",
    "Câmeras de segurança",
    "Campo de futebol",
    "Campo de gramado",
    "Canil",
    "Captação de água da chuva",
    "Carpete",
    "Casa fundos",
    "Casa na árvore",
    "Casa para caseiro",
    "Casa para hóspede",
    "Casa térrea",
    "Celeiro",
    "Chalé",
    "Churrasqueira",
    "Churrasqueira a carvão",
    "Churrasqueira integrada na cozinha",
    "Chuveiro externo",
    "Circuito Interno TV",
    "Cisterna",
    "Clarabóia",
    "Closet",
    "Closet Sr.",
    "Closet Sr. e Closet Sra.",
    "Closet Sra.",
    "Cobertura com vidro retrátil",
    "Conceito aberto",
    "Contra-piso",
    "Copa",
    "Copa com armário(s)",
    "Corredor com armário",
    "Corredor lateral",
    "Corrimão aço inox",
    "Cortina(s)",
    "Cortinas automatizadas",
    "Cozinha",
    "Cozinha americana",
    "Cozinha ampla",
    "Cozinha com área de serviço integrada",
    "Cozinha com armário(s)",
    "Cozinha com Ilha",
    "Cozinha com passa prato",
    "Cozinha com refeitório",
    "Cozinha conceito aberto",
    "Cozinha conjugada",
    "Cozinha em porcelanato e bancada em granito",
    "Cozinha equipada",
    "Cozinha gourmet",
    "Cozinha independente",
    "Cozinha Industrial",
    "Cozinha planejada",
    "Cozinha revestida em cerâmica",
    "Cuba de mármore",
    "Cuba de vidro",
    "Curral",
    "Deck",
    "Decorado",
    "Demi suíte",
    "Dependência de empregada",
    "Dependência para funcionários",
    "Depósito",
    "Depósito privado na garagem",
    "Despensa",
    "Dormitório para hóspedes",
    "Ducha",
    "Ducha higiênica",
    "Edícula",
    "Edícula com churrasqueira",
    "Eletrodomésticos",
    "Elevador com digital",
    "Elevador privativo",
    "Elevador(es)",
    "Entrada de serviço",
    "Entrada lateral independente",
    "Entrada social",
    "Equipamento de aquecimento",
    "Escada em granito",
    "Escada em madeira",
    "Escada em mármore",
    "Escadaria",
    "Escritório",
    "Espaço funcional",
    "Espaço gourmet",
    "Espaço para convenções",
    "Espelho d'água",
    "Esquadrias automatizadas",
    "Esquadrias de alumínio",
    "Esquadrias de madeira",
    "Esquadrilhas PVC",
    "Esquina",
    "Estação de gás",
    "Estacionamento",
    "Estrebaria",
    "Estúdio de musica",
    "Estufa",
    "Fechadura biométrica",
    "Fechadura eletrônica",
    "Fogão a lenha",
    "Fogão cook top",
    "Fonte com lago",
    "Forno a lenha",
    "Forno elétrico",
    "Forro de madeira",
    "Forro em drywall",
    "Forro em gesso",
    "Forro em PVC",
    "Forro rebaixado",
    "Galinheiro",
    "Garden",
    "Garden com churrasqueira",
    "Gerador",
    "Gradeado",
    "Gradil",
    "Guarita",
    "Guarita com segurança",
    "Hall com pé direito duplo",
    "Hall de entrada",
    "Hall de entrada decorado",
    "Heliporto",
    "Hidromassagem",
    "Hobby box",
    "Home theater",
    "Horta",
    "Iluminação automatizada",
    "Iluminação em LED",
    "Iluminação natural",
    "Infraestrutura de ar condicionado",
    "Infraestrutura para aquecedor",
    "Infraestrutura para aspiração central",
    "Infraestrutura para automação",
    "Infraestrutura para coifa",
    "Infraestrutura para desembaçador de espelho",
    "Infraestrutura para elevador",
    "Infraestrutura para persianas",
    "Infraestrutura para sauna",
    "Infraestrutura para som",
    "Infraestrutura para toalheiro elétrico",
    "Internet wi-fi",
    "Interruptores Touch",
    "Isento condomínio",
    "Isolamento acústico",
    "Jacuzzi",
    "Janela automatizada",
    "Janela bay window",
    "Janela de PVC",
    "Janela em madeira",
    "Janelas amplas",
    "Janelas amplas com persiana de enrolar",
    "Janelas antirruído",
    "Janelas com persianas elétricas",
    "Janelas em alumínio",
    "Jardim",
    "Jardim de Inverno",
    "Jardim gramado",
    "Jardim suspenso",
    "Lago",
    "Lagoa para pesca",
    "Lareira",
    "Lareira de Jardim",
    "Lavabo",
    "Lavabo com piso aquecido",
    "Lavanderia",
    "Lavanderia com água aquecida no tanque",
    "Lavanderia com armário(s) planejado(s)",
    "Living amplo",
    "Lounge",
    "Lounge com churrasqueira",
    "Lounge gourmet",
    "Lustre",
    "Manobrista",
    "Manta acústica na laje",
    "Medidor individual",
    "Meio de quadra",
    "Metais de alto padrão",
    "Mezanino",
    "Mezanino com escritório",
    "Mobiliado",
    "Monta carga",
    "Móveis assinados",
    "Móveis planejados",
    "Murado",
    "Muro de vidro",
    "Nicho esculpido no banheiro",
    "Ofurô",
    "Olho d'água",
    "Painel solar",
    "Paisagismo",
    "Papel de parede",
    "Parede reflexiva de vidro",
    "Paredes com isolamento",
    "Paredes duplas",
    "Pátio",
    "Pátio de serviço",
    "Pé direito alto",
    "Pé direito duplo",
    "Pé direito duplo na sala",
    "Pergolado",
    "Persiana(s)",
    "Persianas com blackout",
    "Persianas elétricas",
    "Pintura acrílica",
    "Piscina",
    "Piscina aquecida",
    "Piscina coberta",
    "piscina e sauna privativa",
    "Piscina infantil",
    "Piso aquecido",
    "Piso ardósia",
    "Piso de cerâmica",
    "Piso de concreto polido",
    "Piso de granito",
    "Piso de madeira",
    "Piso de madeira laminada",
    "Piso de mármore",
    "piso de tábua",
    "Piso elevado",
    "Piso em parquet",
    "Piso emborrachado",
    "Piso frio",
    "Piso laminado",
    "Piso porcelanato",
    "Piso taco de madeira",
    "Piso vinílico",
    "Pista de cooper",
    "Pista de pouso",
    "Pista de skate",
    "Pizza grill",
    "Planta flexível",
    "Playground",
    "Poço",
    "Ponto de água quente",
    "Ponto para carro elétrico",
    "Ponto para triturador pia cozinha",
    "Ponto para TV no banheiro",
    "Porão",
    "Porcelanato acetinado",
    "Porta maciça",
    "Porta pivotante",
    "Portão",
    "Portão automático",
    "Portaria remota",
    "Portas antiruído",
    "Portas com borracha amortecedora",
    "Portas em laca",
    "Potencial construtivo",
    "Previsão para elevador",
    "Projeto de iluminação",
    "Quadra beach tennis",
    "Quadra de squash",
    "Quadra de tênis",
    "Quadra esportiva",
    "Quarto(s) amplo(s)",
    "Quarto(s) com ar condicionado",
    "Quarto(s) com armário(s)",
    "Quarto(s) com banheira",
    "Quarto(s) com closet",
    "Quarto(s) com piso laminado",
    "Quarto(s) com sacada",
    "Quarto(s) de solteiro",
    "Quarto(s) mobiliado",
    "Quintal",
    "Quintal com paisagismo",
    "Rebaixamento em gesso",
    "Recepção",
    "Reconhecimento facial",
    "Rede tela de proteção nas janelas",
    "Reflorestamento",
    "Reformado",
    "Reserva nativa",
    "Riacho",
    "Rodapé de madeira",
    "Rodapé em laca",
    "Rodapé em porcelanato",
    "Rodapés em poliestireno",
    "Sacada",
    "Sacada com churrasqueira",
    "Sacada com vista panorâmica",
    "Sacada envidraçada",
    "Sala ampla",
    "Sala ampla para 2 ambientes",
    "Sala ampla para 3 ambientes",
    "Sala ampla para 4 ambientes",
    "Sala ampla para 6 ambientes",
    "Sala c/ isolamento acústico",
    "Sala com armário(s)",
    "Sala com sacada",
    "Sala conjugada com cozinha",
    "Sala de cinema",
    "Sala de estar",
    "Sala de estar ampla",
    "Sala de estar com bar",
    "Sala de estar e jantar amplas",
    "Sala de estudo",
    "Sala de jantar",
    "Sala de jantar com churrasqueira",
    "Sala de jogos",
    "Sala de leitura",
    "Sala de massagem",
    "Sala de pilates",
    "Sala de pintura",
    "Sala de sinuca",
    "Sala de TV",
    "Sala de vinho",
    "Sala intima",
    "Sala mobiliada",
    "Sala(s) ampla",
    "Sala(s) de reunião",
    "Salão de festas privativo",
    "Sanca em gesso",
    "Sauna",
    "Sauna e hidromassagem",
    "Semi suítes",
    "Semimobiliado",
    "Serviço de quarto",
    "Sistema de aproveitamento de água",
    "Sistema de aquecimento",
    "Sistema de aquecimento solar",
    "Sistema de geração de energia fotovoltaica",
    "Sistema de irrigação",
    "Sistema de pressurização",
    "Sistema de prevenção de incêndio",
    "Sistema de segurança",
    "Sistema de som",
    "Sótão",
    "Spa",
    "Suíte com armário(s) planejado",
    "Suíte com closet",
    "Suíte com closet e banheira",
    "Suíte com closet e hidromassagem",
    "Suíte com cuba dupla",
    "Suíte com lareira",
    "Suíte com sacada",
    "Suíte master",
    "Suíte master com closet",
    "Suíte master com hidromassagem",
    "Suíte(s)",
    "Tampo de granito",
    "Tanque de lavar roupa",
    "Tanque de louça",
    "Tanque de peixe",
    "Telha galvanizada",
    "Telhado shingle",
    "Telhas de policarbonato",
    "Telhas em cimento",
    "Terraço",
    "Terraço com churrasqueira",
    "Terraço com cobertura retrátil",
    "Terraço com hidromassagem",
    "Terraço com piscina",
    "Teto em gesso",
    "Teto em madeira",
    "Teto retrátil",
    "Teto retrátil automatizado",
    "Tirolesa",
    "Toalheiro elétrico",
    "Tomadas USB",
    "Transformador",
    "Triplex",
    "Turismo rural",
    "TV a cabo",
    "Vaga(s) de garagem",
    "Vaga(s) de garagem coberta",
    "Vaga(s) de garagem descoberta",
    "Vaga(s) de garagem rotativa",
    "Vaga(s) para visitantes",
    "Varanda",
    "Varanda com churrasqueira",
    "Varanda com jacuzzi",
    "Varanda com piscina",
    "Varanda gourmet",
    "Venezianas integradas as janelas",
    "Ventilação natural",
    "Ventilador de teto",
    "Vestiário",
    "Vestiário feminino",
    "Vestiário masculino",
    "Vidros de alta qualidade",
    "Vidros termo acústico",
    "Vista panorâmica",
    "Vista para o mar",
    "Vista permanente",
    "Viveiro",
    "Wi-fi"
]




import { computed, ref, watch } from 'vue';
import Slugify from 'slugify';
import { Tipo, tipo_repository } from '@/store/tipos';
import { v7 } from 'uuid'
import { dominios } from '@/store/dominios';
import { Caracteristica, caracteristica_repository } from '@/store/caracteristicas';

const slugify = (str: string): string => {
    return Slugify(str, {
        locale: 'pt',
        lower: true
    })
}

type ConfiguracaoTipo = {
    id: string
    tipo_leadlar_id: number
    tipo_chaves_na_mao: string
}

type ConfiguracaoCaracteristica = {
    id: string
    caracteristica_leadlar_id: number
    caracteristica_chaves_na_mao: string
}

type Props = {
    modelValue: {
        tipos: ConfiguracaoTipo[],
        caracteristicas: ConfiguracaoCaracteristica[]
    }
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: Record<string, any>): void
}>()

let default_tipos: ConfiguracaoTipo[] = props.modelValue.tipos||[]
let default_caracteristicas: ConfiguracaoCaracteristica[] = props.modelValue.caracteristicas||[]

const dominio = computed(() => {
    if (dominios.value.length === 0) {
        return ''
    }

    if (dominios.value.length > 1) {
        let record = dominios.value.find(row => !row.default)
        if (record) {
            return record.titulo
        }
    }


    let [dominio] = dominios.value
    return dominio.titulo

})

const url = computed(() => {
    return `https://${dominio.value}/imoveis/chaves-na-mao/listing.xml`
})

if (props.modelValue.tipos) {
    for (let config of props.modelValue.tipos) {
        let row = default_tipos.find(({ tipo_chaves_na_mao }) => tipo_chaves_na_mao === config.tipo_chaves_na_mao)
        if (!row) {
            continue
        }
        row.tipo_leadlar_id = config.tipo_leadlar_id
    }
}

if (props.modelValue.caracteristicas) {
    for (let config of props.modelValue.caracteristicas) {
        let row = default_caracteristicas.find(({ caracteristica_chaves_na_mao }) => caracteristica_chaves_na_mao === config.caracteristica_chaves_na_mao)
        if (!row) {
            continue
        }
        row.caracteristica_leadlar_id = config.caracteristica_leadlar_id
    }
}



const tipos = ref<Tipo[]>([])
const caracteristicas = ref<Caracteristica[]>([])

const configuracao_tipos = ref<ConfiguracaoTipo[]>(default_tipos)
const configuracao_caracteristicas = ref<ConfiguracaoCaracteristica[]>(default_caracteristicas)


const tipos_map = computed<Map<number, string>>(() => {
    let m: Map<number, string> = new Map()

    for (let tipo of tipos.value) {
        m.set(tipo.id, tipo.titulo)
    }

    return m
})


const caracteristica_map = computed<Map<number, string>>(() => {
    let m: Map<number, string> = new Map()

    for (let caracteristica of caracteristicas.value) {
        m.set(caracteristica.id, caracteristica.titulo)
    }

    return m
})


const loadTipos = async () => {

    try {
        tipos.value = await tipo_repository.getAll()
        for (let tipo of tipos.value) {
            let tipo_chaves_na_mao = tipos_chaves_na_mao.find(tipo_chaves_na_mao => slugify(tipo_chaves_na_mao) === slugify(tipo.titulo))
            let config = configuracao_tipos.value.find(row => row.tipo_leadlar_id === tipo.id)

            if (!config) {
                config = {
                    id: v7(),
                    tipo_leadlar_id: tipo.id,
                    tipo_chaves_na_mao: ''
                }
                configuracao_tipos.value.push(config)
            }
            if (config.tipo_chaves_na_mao === '' && tipo_chaves_na_mao) {
                config.tipo_chaves_na_mao = tipo_chaves_na_mao
            }
        }
    } catch (error) {
        console.error(error)
    }
}

const loadCaracteristicas = async () => {
    try {
        let records = await caracteristica_repository.search()
        caracteristicas.value = records
        
        for (let caracteristica of caracteristicas.value) {
            let caracteristica_chaves_na_mao = caracteristicas_chaves_na_mao.find(caracteristica_chaves_na_mao => slugify(caracteristica_chaves_na_mao) === slugify(caracteristica.titulo))
            let config = configuracao_caracteristicas.value.find(row => row.caracteristica_leadlar_id === caracteristica.id)

            if (!config) {
                config = {
                    id: v7(),
                    caracteristica_leadlar_id: caracteristica.id,
                    caracteristica_chaves_na_mao: ''
                }
                configuracao_caracteristicas.value.push(config)
            }
            if (config.caracteristica_chaves_na_mao === '' && caracteristica_chaves_na_mao) {
                config.caracteristica_chaves_na_mao = caracteristica_chaves_na_mao
            }
        }
        
    } catch (error) {
        console.error(error)
    }
}

const update = () => {
    emit('update:modelValue', {
        ...props.modelValue,
        tipos: configuracao_tipos.value,
        caracteristicas: configuracao_caracteristicas.value
    })
}

const load = async () => {
    await Promise.all([
        loadTipos(),
        loadCaracteristicas()
    ])
    update()
}


watch(configuracao_tipos, () => {
    update()
})

load()

</script>
<style>
.modal-content {
    width: 80vw;
}

input:invalid {
    color: red;
}
</style>